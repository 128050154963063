

.dropDownButton {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

}

.dropDownButton .drop-down-button {
    outline: none;
    background-color: transparent;
    border: none;
    font-size: x-large;
    cursor: pointer;
    width: 30px;
    height: 40px;
    padding-bottom: 10px;
}

.dropDownButton-dropDown {
    position: absolute;
    top: 100%;
    right: 0;
    margin: 0;
    padding: 0px 0;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 150px;
}

