
.addWidget {
    display: flex;
    flex-direction: column;
}

.innerWidget {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.innerWidget button {
    /*flex-grow: 1;*/
    border: 2px solid transparent; /* Transparente Außenlinie */
    background-color: transparent;
    border-radius: 50%; /* Runde Form */
    width: 60px;
    height: 60px;
    margin: 0 10px;
    padding: 15px; /* Innenabstand */
    font-size: 25px; /* Schriftgröße */
    cursor: pointer; /* Hand-Cursor */
    outline: none; /* Keine Umrandung beim Klicken */
    transition: border 0.3s; /* Übergangseffekt */
}

.innerWidget button:hover {
    /*flex-grow: 1;*/
    border: 2px solid lightgray; /* Transparente Außenlinie */
    background-color: lightgray;
    cursor: pointer; /* Hand-Cursor */
    outline: none; /* Keine Umrandung beim Klicken */
    transition: border 0.3s; /* Übergangseffekt */
}

.addWidget {
    position: fixed;
    bottom: -340px;
    width: 400px;
    margin: auto;
    left: 0;
    right: 0;
    background: white;
    padding: 20px 0;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    transition: bottom 0.8s ease-in-out;
  }

  
  .addWidget.open {
    bottom: 0;
  }

.circle {
    width: 50px;
    height: 50px;
    line-height: 50px;

    font-size: x-large;

    border-radius: 25px;
    border: 5px solid black;
    vertical-align: middle;
    transition: background-color 0.4s linear;
}

.red {
    background-color: #E57373;
    border-color: #D32F2F;
}

.green {
    background-color: #81C784;
    border-color: #388E3C;
}

.blue {
    background-color: #64B5F6;
    border-color: #1976D2;
}

.yellow {
    background-color: #FFF176;
    border-color: #FBC02D;
}

.orange {
    background-color: #FFB74D;
    border-color: #F57C00;
}

.purple {
    background-color: #BA68C8;
    border-color: #8E24AA;
}

.circle.white {
    background-color: white;
    border-color: #E0E0E0;
}

.circle.black {
    background-color: black;
    border-color: #424242;
    color: white;
}

.circle.pink {
    background-color: #F8BBD0;
    border-color: #EC407A;  
}

.circle.mint {
    background-color: #B2EBF2;
    border-color: #4DD0E1;
}