.route-detail {
    position: fixed;
    bottom: -440px;
    height: 400px;
    width: 360px;
    left: 0;
    right: 0;
    margin: auto;
    background: #f0f0f0;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.8s ease-in-out;
}

table {
    width: 100%;
}

.route-detail.open {
    bottom: 0;
}

.route-detail .circle {
    position: relative;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: relative;
    top: -35px;
    margin-bottom: -35px;

    font-size: x-large;

    border-radius: 25px;
    vertical-align: middle;
    transition: background-color 0.4s linear;
}

.route-detail .circle .circle-close {
    position: absolute;
    top: 30px;
    right: 0;
    left: 0;
    line-height: 15px;

}

.route-detail .circle .circle-close .close-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: transparent;
    font-size: small;
    line-height: 15px;
    border-radius: 50%;
}

.table-wrapper {
    max-height: 350px;
    overflow-y: scroll;
}

.dropdown-container {
    position: absolute;
    top: 10px;
    right: 20px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.challenge-list {
    list-style-type: none;
    padding: 0;
}

.challenge-item {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.info {
    flex-grow: 1;
}

.name {
    font-weight: bold;
    font-size: 18px;
}

.date {
    font-size: 14px;
    color: #666;
}

.status {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    white-space: nowrap;
}

.completed {
    background-color: #e6ffe6;
    color: #006600;
}

.not-completed {
    background-color: #ffe6e6;
    color: #660000;
}

.red {
    background-color: #E57373;
    border-color: #D32F2F;
}

.green {
    background-color: #81C784;
    border-color: #388E3C;
}

.blue {
    background-color: #64B5F6;
    border-color: #1976D2;
}

.yellow {
    background-color: #FFF176;
    border-color: #FBC02D;
}

.orange {
    background-color: #FFB74D;
    border-color: #F57C00;
}

.purple {
    background-color: #BA68C8;
    border-color: #8E24AA;
}

.add-entry-form {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="date"],
select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.add-log-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.add-log-button:hover {
    background-color: #45a049;
}