
.add-button {
    position: fixed;
    bottom: 30px;
    margin: auto;
    background: transparent;
    padding: 0;
    left: 0;
    right: 0;
    width: 60px;
    transition: bottom 0.8s ease-in-out;
}

.add-button button {
    /*flex-grow: 1;*/
    width: 60px;
    height: 60px;
    line-height: 50px;

    font-size: x-large;

    border-radius: 25px;
    border: 5px solid #2980B9;
    vertical-align: middle;
    background-color: #3498DB;
    color: #D1F2EB;
    margin: 0 0;
    padding: 0; /* Innenabstand */
    cursor: pointer; /* Hand-Cursor */
    outline: none; /* Keine Umrandung beim Klicken */
}