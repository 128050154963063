.drop-down-content-button {
    display: flex;
    padding: 15px 16px;
    cursor: pointer;
}

.drop-down-button-icon {
    padding-right: 10px;
}

.drop-down-content-button:hover {
    background-color: #f5f5f5;
}