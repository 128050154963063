/* General Styles */
 
  /* Scoreboard Container */
  
  /* Header */
  .scoreboard-header {
    /*background-color: #2c3e50;*/
    color: #2c3e50;
    padding: 1rem 0;
    display: flex;
  }

  .scoreboard-header button {
    border: none;
    background-color: transparent;
    width: 50px;

  }
  
  .scoreboard-header h2 {
    flex-grow: 1;
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* Rows */
  .scoreboard-content {
    padding: 1rem;
  }
  
  .scoreboard-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s;
  }
  
  /* Player Names and Scores */
  .player {
    font-weight: bold;
    color: #333;
  }
  
  .score {
    background-color: #2c3e50;
    color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    min-width: 40px;
    text-align: center;
    font-weight: bold;
  }
  
  
  /* Highlight Top Score */
  .scoreboard-row:first-child .score {
    background-color: #ffeb3b;
    color: #333;
    font-size: 1.2rem;
  }
  